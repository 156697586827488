$breakpoint-md: 1000px;

.markdown-editor {
  display: flex;

  @media (max-width: $breakpoint-md) {
    flex-direction: column-reverse;
  }

  .column {
    flex-grow: 1;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .preview-container {
    .preview {
      overflow: scroll;
      height: 500px;
      border: 1px solid #ededed;
      padding: 5px;
    }
  }

  .editor {
    min-width: 500px;
    max-width: 500px;
  }
}

#resources {
  height: 500px;
  resize: none;
}
