$black: #363636;
$green: #5bb75b;
$red: #ff5850;
$warning: #f90;
$white: #fff;

.hub-c-toolbar__item-selected-item {
  background-color: #edf8ff;
}

.status-icon {
  font-size: 12px;
}

.sub-text {
  font-weight: normal;
}

.namespace-selector {
  width: 100%;
}

.loading {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.namespace-selector-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .label {
    width: 100px;
    color: black;
    text-align: left;
  }

  .selector {
    flex-grow: 1;
  }
}

.import-list {
  & > div {
    margin-bottom: 16px;
  }

  .pf-c-toolbar {
    padding: 0;
  }

  .pf-c-toolbar__content-section {
    margin: 0;
  }

  .list-container {
    display: flex;
    align-items: center;
    padding: 10px;

    .left {
      margin-right: 10px;
    }

    border-bottom: 1px solid #ededed;
  }

  .list-container:hover {
    background-color: #edf8ff;
  }

  .search-box {
    margin-bottom: 24px;
  }
}

.hub-import-console {
  .title-bar {
    position: relative;
    margin-top: 15px;
    margin-bottom: 20px;
    background-color: #f0f0f0;
    min-height: 25px;
    padding: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 2px;

    .data-title {
      font-weight: bold;
    }

    pre {
      white-space: pre-wrap;
    }

    .stats {
      text-align: right;
      font-size: 12px;
      color: $black;
    }
  }

  .title-container {
    display: inline-block;

    .title {
      display: inline;
      font-size: 18px;
    }

    .refreshing-icon {
      display: inline;
      font-size: 18px;
      margin-left: 10px;
      opacity: 0;
      transition: 2s ease;
    }

    .refreshing-icon.refreshing {
      opacity: 1;
    }
  }

  // Give the last element some extra height so that it doesn't cut off
  // messages when scroling down to it.
  .last-message {
    height: 100px;
    width: 5px;
    position: relative;
    top: -150px;
  }

  .message-list {
    background-color: $black;
    padding: 10px;
    border: 1px solid $black;
    border-radius: 2px;
    color: $white;
    line-height: 18px;
    margin-bottom: 50px;
    word-break: break-word;

    .log-follow-button {
      position: relative;
      top: 0;
      float: right;
      font-size: 18px;
    }

    .follow-active {
      color: $green;
    }
  }
}

.error,
.failed {
  color: $red;
}

.warning {
  color: $warning;
}

.success {
  color: $green;
}
