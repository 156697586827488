.options-table {
  td,
  th {
    border: 1px solid black;
    padding: 5px;
    vertical-align: top;
  }

  ul {
    margin-top: 0;
  }

  small {
    margin-bottom: 0;
  }

  // ensures the bottom of the table always has a border even
  // if there is a spacer at the bottom which might otherwise cause
  // a gap
  border-bottom: 1px solid black;
  width: 100%;

  .spacer {
    width: 20px;
    border-top: 0;
    border-bottom: 0;
  }

  .parent {
    border-bottom: 0;
  }
}

.blue {
  color: var(--pf-global--info-color--100);
}

.red {
  color: var(--pf-global--danger-color--100);
}

.green {
  color: var(--pf-global--success-color--200);
}

.option-name {
  font-weight: bold;
}

.plugin-config {
  margin-bottom: 16px;
}

.plugin-raw {
  white-space: pre-wrap;
}

.inline-code {
  background-color: #e6e9e9;
  font-family: var(--pf-global--FontFamily--monospace);
  display: inline-block;
  border-radius: 2px;
  padding: 0 2px;
}
