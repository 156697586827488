.controls {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
}

.top {
  padding-top: 0;
  padding-bottom: 16px;
}

.bottom {
  padding-top: 16px;
  padding-bottom: 0;
}
