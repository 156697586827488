$breakpoint-md: 1000px;

.hub-card-row {
  @media (max-width: $breakpoint-md) {
    flex-direction: column-reverse;
  }

  display: flex;

  .fields {
    flex-grow: 1;
    margin-right: 15px;
  }

  .card {
    @media (min-width: $breakpoint-md) {
      border-left: 24px solid #ededed;
      border-bottom: 24px solid #ededed;
      margin-top: -15px;
      margin-right: -15px;
    }
  }
}

.useful-links {
  display: flex;
  margin-bottom: 10px;

  .link-name {
    flex-grow: 1;
    flex-basis: 0;
  }

  .link-url {
    flex-grow: 2;
    flex-basis: 0;
    margin-left: 20px;
  }

  .link-button {
    display: flex;
    align-items: top;
    margin-left: 10px;
    margin-top: 8px;

    .link-container {
      width: 20px;
    }
  }
}

.account-ids {
  display: flex;
  margin-bottom: 10px;

  .account-id {
    flex-grow: 1;
  }

  .account-add {
    flex-grow: 2;
    margin-left: 20px;
    margin-top: 5px;
  }

  .account-button {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}
