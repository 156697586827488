.hub-c-table-content {
  width: 100%;

  th {
    font-weight: bold;
  }
}

.hub-c-toolbar__item-type-selector {
  margin-left: 16px;
  text-transform: capitalize;
}

.hub-c-toolbar__item-selected-item,
.hub-c-toolbar__item-type-selector:hover {
  border-bottom: 2px solid var(--pf-global--link--Color);
  padding-bottom: 2px;
  margin-bottom: -4px;
  background-color: white;
}
