.hub-entry {
  margin-top: 5px;
}

.hub-right-col {
  width: 200px;
}

.hub-numeric-label-label {
  font-size: var(--pf-global--FontSize--xs);
  color: var(--pf-global--color--200);
}
