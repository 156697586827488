.install-title {
  min-width: 100px;
  vertical-align: middle;
  display: table-cell;
}

.hub-readme-container {
  position: relative;
  max-height: 300px;
  min-height: 50px;
  overflow: hidden;
}

.hub-fade-out {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255 255 255 / 0%),
    rgba(255 255 255 / 100%) 90%
  );
  width: 100%;
  height: 50px;
}

.download-button {
  padding-left: 0;
}

.info-panel {
  padding: 8px;
}

.hub-collection-download-alert {
  width: 100%;
}

.hub-collection-download-alert > h4 {
  margin-top: 0;
}
