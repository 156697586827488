$charcoal: #808080;
$green: #5bb75b;

.upload-collection {
  .file-error-messages {
    color: var(--pf-global--danger-color--100);

    &.skipped {
      color: var(--pf-global--warning-color--100);
    }
  }

  .upload-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .upload-file-label {
    display: inline-block;
    cursor: pointer;
    width: 100%;
    font-weight: normal;

    .upload-box {
      display: flex;
      border-style: solid;
      border-width: thin;
      width: 100%;
      border-radius: 3px;

      .upload-button,
      .upload-text {
        padding: 5px;
      }

      .upload-button {
        border-right: thin solid;
        padding-left: 10px;
        padding-right: 10px;
      }

      .upload-button:hover {
        color: white;
        background-color: $charcoal;
      }

      .upload-text {
        position: relative;
        width: 100%;
      }

      .loading-bar {
        position: absolute;
        height: 3px;
        background-color: $green;
        bottom: 0;
        left: 0;
      }
    }
  }
}
