$blue: #00659c; /* logo blue */
$grey: #9c9c9c;

.hub-SortTable-headers {
  .active {
    color: $blue;
  }

  .inactive {
    color: $grey;
  }
}
