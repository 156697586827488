.icon {
  margin-left: 8px;
  color: var(--pf-global--icon--Color--light);

  &:hover {
    color: var(--pf-global--link--Color--dark);
  }
}

.selected {
  color: var(--pf-global--link--Color--dark);
}
