.hub-namespace-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card-area {
    margin-top: 24px;
    margin-left: 24px;
    flex-grow: 1;
  }

  .footer {
    border-top: 1px solid #d8d8d8;
    flex-shrink: 0;
  }
}
